<template lang="pug">
    .layout-general
        form.form-general.card-bg-theme.p-6.rounded.shadow(@submit="submit($event)")
            .form-general__header
                h2 {{ page_title }}
                router-link(to="/ancaman")
                    b-button(text="Kembali" text_icon_left="chevron-left" type="button")
            .form-general__body
                .grid.grid-cols-11.gap-8.mb-16
                    .col-span-4.pr-12
                        h2.text-xl.font-bold.mb-2 Informasi Ancaman
                        p.text-gray-400 Pastikan Informasi ancaman diisi sesuai dengan kebutuhan gatra.
                    .col-span-7
                        .mb-12
                            .field
                                label.field-label.pb-4 Pilih Gatra

                                .relative.p-10.text-center(v-if="status_list_gatra.status === 'loading'")
                                    b-loading(text="Memuat data")

                                template(v-else)
                                    //- .grid.grid-cols-3.gap-4
                                    .grid.grid-cols-3.gap-4(v-if="list_gatra && list_gatra.length > 0")
                                        //- .ui-radio(v-for="item in list_gatra")
                                        .ui-radio(v-for="item in list_gatra" :key="item.id")
                                            input(type="radio" :value="item.id" v-model="model.gatra_id" :disabled="route_name === 'GeneralAncamanEdit'")
                                            .ui-radio-style
                                                .box-radio.box-radio--default
                                                    span
                                                label {{ item.label }}

                                        //- b-checkbox.mb-4(v-for="item in list_gatra" :value="item.id" :key="item.id" :label="item.label" v-model="choosenGatra")
                                    
                                    span(v-else) -

                                span.field-message.text-red-500(v-if="$v.model.gatra_id.$error") Gatra wajib diisi.
                                span.field-message.text-red-500(v-if="error_gatra_id !== ''") {{ error_gatra_id }}

                        .field
                            label.field-label Label Ancaman
                            input.field-style(placeholder="Tulis label ancaman disini... " type="text" v-model="model.label")
                            span.field-message.text-red-500(v-if="$v.model.label.$error") Label wajib diisi.
                            span.field-message.text-red-500(v-if="error_label !== ''") {{ error_label }}

            .form-general__footer
                b-button(text="Reset" type="button" @click.native="reset()")
                b-button(text="Simpan" kind="primary" type="submit")

</template>

<script>
import {mapState} from 'vuex';
import {required, maxLength} from 'vuelidate/lib/validators';

export default {
    name: 'GeneralAncamanForm',
    data() {
        return {
            route_name: '',

            model: {
                gatra_id: '',
                label: '',
            },

            error_gatra_id: '',
            error_label: '',
        };
    },
    validations: {
        model: {
            gatra_id: {
                required,
                //- maxLength: maxLength(255),
            },
            label: {
                required,
                //- maxLength: maxLength(255)
            },
        },
    },
    computed: {
        ...mapState('graph', [
            'status_list_gatra',
            'list_gatra',
        ]),
        ...mapState('ancaman', [
            'status_detail',
            'detail',
        ]),
        page_title() {
            let title = 'Ubah Ancaman';
            if (this.route_name === 'GeneralAncamanCreate') {
                title = 'Tambah Ancaman';
            }
            return title;
        },
    },
    watch: {
        page_title() {},
        detail(newVal) {
            this.model.gatra_id = (newVal.gatra_id ? newVal.gatra_id : '');
            this.model.label = (newVal.label ? newVal.label : '');
        },
    },
    methods: {
        setEmptyModel(all=true) {
            for (const key in Object.keys(this.model)) {
                if (all === true) {
                    this.model[Object.keys(this.model)[key]] = '';
                }
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_gatra_id = '';
                this.error_label = '';
            } else if (empty !== true && errors !== null) {
                this.error_gatra_id = errors.gatra_id ? errors.gatra_id : '';
                this.error_label = errors.label ? errors.label : '';
            }
        },
        async reset(direct=false) {
            if (direct === true) {
                // this.setEmptyModel(false);
                this.setEmptyModel(true);
                // reset vuelidate
                this.$nextTick(() => {
                    this.$v.$reset();
                });
                this.setErrors(true);
                // window.scrollTo(0,0);
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda benar-benar ingin mengosongkan semua data yang sudah diisi?',
                    // text: id,
                    confirmButtonText: 'Ya',
                    confirmButtonColor: '#DD6B55',
                    cancelButtonText: 'Tidak',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                        // if (this.route_name === 'GeneralProfilePage') {
                        //     this.setEmptyModel(false);
                        //     // reset vuelidate
                        //     this.$nextTick(() => { this.$v.$reset(); });
                        //     this.setErrors(true);
                        //     window.scrollTo(0,0);
                        // } else {
                            this.getData();
                        // }
                        }
                    });
            }
        },
        async getData(update=false) {
            await this.reset(true);
            this.$store.dispatch('graph/getListGatra');


            if (this.route_name === 'GeneralAncamanEdit') {
                Event.$emit('showLoading', true);

                //- console.log(this.$route.params.data);
                this.$store.commit('ancaman/setDetail', this.decodeDetailData(this.$route.params.data));

                // temp
                Event.$emit('showLoading', false);

                //- this.$store.dispatch('ancaman/getDetail', this.id)
                //- .then((resp) => {
                //-     if (resp === 'success') {
                //-         Event.$emit('showLoading', false);
                //-     } else {
                //-         Event.$emit('error');
                //-     }
                //- });

            } else {
                // if (this.route_name === 'GeneralAncamanCreate')
                this.$store.commit('ancaman/setDetail', {});
            }
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (const key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes('$')) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // Event.$emit('error', 'Please fill the form correctly.');
                // Event.$emit('showLoading', false);
                window.scrollTo(0, 0);
                return;
            }

            Event.$emit('showLoading', true);
            let model_data = this.model;
            // model_data.gatra = JSON.stringify(model_data.gatra);
            // console.log(model_data);
            model_data = Object.keys(model_data).map((key) => model_data[key]);

            if (this.route_name === 'GeneralAncamanCreate') {
                this.$store.dispatch('ancaman/create', model_data)
                    .then((resp) => {
                        if (resp.result === 'success') {
                            if (save_continue === false) {
                                this.$router.push('/ancaman');
                            } else {
                                this.$router.push('/ancaman/'+ resp.data.data.id +'/edit');
                            }

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Ancaman berhasil dibuat',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message && resp.data.message.errors) {
                            // this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                                Event.$emit('showLoading', false);
                                window.scrollTo(0, 0);
                            } else {
                                Event.$emit('error');
                            }
                        }
                    });

            // GeneralAncamanEdit
            } else {
                model_data = [this.detail.id].concat(model_data);

                this.$store.dispatch('ancaman/update', model_data)
                .then((resp) => {
                    if (resp.result === 'success') {
                        if (save_continue === false) {
                            this.$router.push('/ancaman');
                        }

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Ancaman berhasil diperbarui',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message && resp.data.message.errors) {
                            this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                            Event.$emit('showLoading', false);
                            window.scrollTo(0, 0);
                        } else {
                            Event.$emit('error');
                        }
                    }
                });
            }
        },
    },
    created() {
        //
    },
    
    mounted() {
        // 
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.route_name = to.name;
            if (to.name === 'GeneralAncamanCreate' || to.name === 'GeneralAncamanEdit') {
                vm.getData();
            }
        });
    },
    beforeRouteLeave(to, from, next) {
        this.route_name = '';
        this.$store.commit('ancaman/setDetail', {});
        next();
    },
};
</script>
